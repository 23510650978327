export default {
  setLoading(state, loading) {
    state.loading = loading;
  },
  setListRequest(state, data) {
    state.listRequest = data;
    state.loading = false;
  },
  setType(state, data) {
    state.listTypeBank = data.filter((e) => e.type === 'request_type_bank');
    state.listTypeNetwork = data.filter(
      (e) => e.type === 'request_type_network'
    );
    state.listStatus = data.filter((e) => e.type === 'request_status');
    state.listSuggest = data.filter((e) => e.type === 'suggest');
    state.loading = false;
  },
  setDetail(state, data) {
    state.infoDetail = data
    state.loading = false
  },
  setDataFile(state, data) {
    state.dataFile = data
  },
  setInfoAdditional(state, data) {
    state.infoAdditionalDetail = {
      ...data,
      receiverOrgType: state.infoDetail.receiverOrgType,
      name: state.infoDetail.name,
    }
    state.loading = false
  },
  setLstAdditional(state, data) {
    state.lstReqAdditional = data.requestAdditional.map(e => ({...e, name: state.infoDetail.name}))
  }
};
