import ConstantAPI from '@/config/ConstantAPI';
import { DataService } from '@/dataService/dataService';
import mutations from '@/vuex/modules/request/mutations';
import { getField, updateField } from 'vuex-map-fields';
import { showConfirm } from '@/util/confirm';
import { checkResponse } from '@/util/common-utils';
import { notification } from 'ant-design-vue';
import { MESSAGES } from '@/util/message-notification';
const state = () => ({
  listTypeBank: [],
  listTypeNetwork: [],
  listStatus: [],
  listRequest: [],
  listSuggest: [],
  loading: false,
  api: ConstantAPI.request,
  infoDetail: null,
  dataFile: [],
  infoAdditionalDetail: null,
  lstReqAdditional: [],
  loadingFile: false
});

const actions = {
  async getType({ commit, state }) {
    try {
      commit('setLoading', true);
      const res = await DataService.callApi(state.api.GET_APP_PARAMS);
      commit('setType', res.data);
    } catch (err) {
      console.log(err);
    }
  },
  async getAllRequest({ commit, state }, params) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(
        state.api.GET_ALL,
        null,
        params,
        null
      );
      commit('setListRequest', response.data.content);
    } catch (err) {
      console.log(err);
    }
  },
  async create({ commit, state }, data) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(
        state.api.CREATE,
        data,
        null,
        null
      );
      checkResponse(response, () => {
        notification.success({
          message: 'Thông báo',
          description: MESSAGES.MESSAGE_005,
          duration: 4,
        });
      });
      commit('setLoading', false);
    } catch (err) {
      console.log(err);
    }
  },
  async getRequestById({ commit, state }, id) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi({
        method: 'GET',
        url: state.api.GET_BY_REQ_ID.url + id,
      });
      commit('setDetail', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  async cancelRequest({ commit, state }, payload) {
    console.log(payload);
    try {
      commit('setLoading', true);
      if (await showConfirm(MESSAGES.MESSAGES_0018)) {
        const response = await DataService.callApi({
          method: 'PATCH',
          url:
            state.api.CANCEL_BY_SENDER.url + payload.id + '/' + payload.orgId,
        });
        let success = false;
        checkResponse(response, () => {
          success = true;
          notification.success({
            message: 'Thông báo',
            description: MESSAGES.MESSAGES_0019,
            duration: 4,
          });
        });
        return success;
      }
      commit('setLoading', false);
    } catch (err) {
      console.log(err);
    }
  },
  handleDataFile({commit}, data) {
    commit('setDataFile', data)
  },
  async createReqAdditional({ commit, state }, data) {
    try {
      commit('setLoading', true);
      const response = await DataService.callApi(state.api.CREATE_REQ_ADDITIONAL, data);
      checkResponse(response, () => {
        notification.success({
          message: 'Thông báo',
          description: MESSAGES.MESSAGE_005,
          duration: 4,
        });
      });
      commit('setLoading', false);
    } catch (err) {
      console.log(err);
    }
  },
  async getReqAdditionalById({ commit, state }, id) {
    try {
      commit('setLoading', false);
      const response = await DataService.callApi({
        method: 'GET',
        url: state.api.GET_REQ_ADDITIONAL_BY_ID.url + id,
      });
      commit('setInfoAdditional', response.data);
    } catch (err) {
      console.log(err);
    }
  },
  setListReqAdditional({commit}, data) {
    commit('setLstAdditional', data)
  },
  resetData({commit}) {
    commit('resetData')
  },
  setLoadingFile({state}, bol) {
    state.loadingFile = bol
  }
};

export default {
  namespaced: true,
  state,
  actions,
  mutations: {
    ...mutations,
    updateField,
  },
  getters: {
    getField,
  },
};
