import { createStore } from 'vuex';
import themeLayout from './modules/themeLayout/actionCreator';
import auth from './modules/authentication/axios/actionCreator';
import role from './modules/system/role/actionCreator';
import menu from './modules/system/menu/actionCreator';
import user from './modules/system/user/actionCreator';
import chartContent from './modules/chartContent/actionCreator';
import clip from './modules/clip/clip/actionCreator';
import category from './modules/category/actionCreator';
import reportNews from './modules/report/news/actionCreator';
import agency from './modules/menu/agency/actionCreator';
import fields from './modules/fields/actionCreator';
import license from './modules/license/actionCreator';
import articles from './modules/articles/actionCreator';
import dataset from './modules/dataset/actionCreator';
import flow from './modules/flow/actionCreator';
import data_approval from './modules/dataApproval/actionCreator';
import file from './modules/files/actionCreator';
import request from './modules/request/actionCreator';
export default createStore({
  modules: {
    themeLayout,
    auth,
    role,
    menu,
    user,
    chartContent,
    clip,
    fields,
    dataset,
    license,
    category,
    reportNews,
    articles,
    flow,
    agency,
    data_approval,
    file,
    request,
  },
});
